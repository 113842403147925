.ham {
  span:nth-of-type(1) {
    transform: rotate(0);
    transition: all 0.2s ease-in-out;
  }

  span:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
    transition: all 0.2s ease-in-out;
  }

  span:nth-of-type(3) {
    transform: rotate(0);
    transition: all 0.2s ease-in-out;
  }
}
.hamCross {
  span:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 30% 10%;
  }
  span:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  span:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 20% 90%;
  }
}
.modal {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
.show {
  height: 130px;
}
.show3 {
  height: 186px;
}
.hiddenCss {
  height: 0;
}
.pinkBtn {
  width: 156px;
  height: 52px;
  &:hover {
    background-color: lighten(#ce6de9, 5%);
  }
}
